import { permissioNames } from "@/utils/constants/user";

export const notFountRoute = {
  path: "*",
  name: "nopage",
  meta: {
    type: "nopage",
    title: "对不起，您没有权限进行这个操作"
  },
  component: () => import(/* webpackChunkName: "nopage" */ "@/views/NoPage.vue")
};

export const staticRoutes = [
  {
    path: "/",
    name: "login",
    meta: {
      type: "adminlogin",
      title: "登录"
    },
    component: () => import(/* webpackChunkName: "login" */ "@/views/Login.vue")
  },
  {
    path: "/noreg",
    name: "noreg",
    props: true,
    meta: {
      type: "noreg",
      title: "未注册"
    },
    component: () => import(/* webpackChunkName: "noreg" */ "@/views/NoReg.vue")
  }
];

export const dynamicRoutes = [
  {
    path: "/admin",
    name: "admin",
    meta: {
      type: "admin"
    },
    component: () =>
      import(/* webpackChunkName: "admin" */ "@/views/admin/Admin.vue"),
    children: [
      {
        path: "report",
        name: "report",
        meta: {
          type: "admin",
          navi: {
            iconName: "mdi-text-box-search",
            title: "案例管理"
          },
          perms: [
            permissioNames.case_all.value,
            permissioNames.case_read.value,
            permissioNames.case_edit.value,
            permissioNames.case_export.value,
            permissioNames.case_grouprpt_read.value,
            permissioNames.case_rpt_edit.value
          ]
        },
        component: () =>
          import(
            /* webpackChunkName: "report" */
            "@/views/admin/AdminCaseReport.vue"
          )
      },
      {
        path: "testperson",
        name: "testperson",
        meta: {
          type: "admin",
          navi: {
            iconName: "mdi-account-multiple",
            title: "被试人员管理"
          },
          perms: [permissioNames.person_all.value]
        },
        component: () =>
          import(
            /* webpackChunkName: "testperson" */
            "@/views/admin/AdminTestPerson.vue"
          )
      },
      {
        path: "node",
        name: "confignode",
        meta: {
          type: "admin",
          navi: {
            iconName: "mdi-layers",
            title: "测评单位管理"
          },
          perms: [permissioNames.node_all.value]
        },
        component: () =>
          import(
            /* webpackChunkName: "confignode" */
            "@/views/admin/AdminConfigNode.vue"
          )
      },
      {
        path: "config",
        name: "configbase",
        meta: {
          type: "admin",
          navi: {
            iconName: "mdi-pencil-box-multiple",
            title: "测评"
          },
          perms: [
            permissioNames.group_sca_all.value,
            permissioNames.open_sca_all.value,
            permissioNames.indv_sca_all.value
          ]
        },
        component: () =>
          import(
            /* webpackChunkName: "configbase" */
            "@/views/admin/AdminConfig.vue"
          ),
        children: [
          {
            path: "grouplbgroup",
            name: "grouplbgroup",
            props: () => ({ type: "group" }),
            meta: {
              type: "admin",
              navi: {
                iconName: "mdi-account-multiple-outline",
                title: "团体测评"
              },
              perms: [permissioNames.group_sca_all.value]
            },
            component: () =>
              import(
                /* webpackChunkName: "grouplbgroup" */
                "@/views/admin/AdminLbGroup.vue"
              )
          },
          {
            path: "openlbgroup",
            name: "openlbgroup",
            props: () => ({ type: "open" }),
            meta: {
              type: "admin",
              navi: {
                iconName: "mdi-earth",
                title: "开放测评"
              },
              perms: [permissioNames.open_sca_all.value]
            },
            component: () =>
              import(
                /* webpackChunkName: "openlbgroup" */
                "@/views/admin/AdminLbGroup.vue"
              )
          },
          {
            path: "individuallbgroup",
            name: "individuallbgroup",
            props: () => ({ type: "individual" }),
            meta: {
              type: "admin",
              navi: {
                iconName: "mdi-account-outline",
                title: "个人测评"
              },
              perms: [permissioNames.indv_sca_all.value]
            },
            component: () =>
              import(
                /* webpackChunkName: "openlbgroup" */
                "@/views/admin/AdminLbGroup.vue"
              )
          }
        ]
      },
      {
        path: "lb",
        name: "lbmanage",
        meta: {
          type: "admin",
          navi: {
            iconName: "mdi-clipboard-text-multiple-outline",
            title: "量表"
          },
          perms: [permissioNames.lb_all.value]
        },
        component: () =>
          import(
            /* webpackChunkName: "lbmanage" */
            "@/views/admin/AdminConfig.vue"
          ),
        children: [
          {
            path: "lbinfomanage",
            name: "lbinfomanage",
            meta: {
              type: "admin",
              navi: {
                iconName: "mdi-table-settings",
                title: "文档与设置"
              },
              perms: [
                permissioNames.lb_doc_read.value,
                permissioNames.lb_all.value
              ]
            },
            component: () =>
              import(
                /* webpackChunkName: "lbinfomanage" */
                "@/views/admin/AdminLbInfoManage.vue"
              )
          },
          {
            path: "lbcategory",
            name: "lbcategory",
            meta: {
              type: "admin",
              navi: {
                iconName: "mdi-television-guide",
                title: "分类管理"
              },
              perms: [permissioNames.lb_all.value]
            },
            component: () =>
              import(
                /* webpackChunkName: "lbinfomanage" */
                "@/views/admin/AdminLbCategory.vue"
              )
          },
          {
            path: "lbwaring",
            name: "lbwaring",
            meta: {
              type: "admin",
              navi: {
                iconName: "mdi-alert-plus-outline",
                title: "预警管理"
              },
              perms: [permissioNames.lb_all.value]
            },
            component: () =>
              import(
                /* webpackChunkName: "lbinfomanage" */
                "@/views/admin/AdminLbWaringManage.vue"
              )
          }
        ]
      },
      {
        path: "settings",
        name: "settings",
        meta: {
          type: "admin",
          navi: {
            iconName: "mdi-cog",
            title: "设置"
          },
          perms: [permissioNames.admin_user_all.value]
        },
        component: () =>
          import(
            /* webpackChunkName: "settings" */
            "@/views/admin/AdminSettings.vue"
          )
      }
    ]
  }
];
