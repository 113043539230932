export const nodeTypes = {
  super: {
    value: "super"
  },
  client: {
    value: "client"
  },
  dept: {
    value: "dept"
  }
};

export const personInputTypes = {
  search: {
    value: "search",
    text: "搜索导入的被试者"
  },
  create: {
    value: "create",
    text: "新建被试者"
  }
};

export const permissioNames = {
  user_login: {
    value: "user_login"
  },
  //#region 案例管理界面
  case_all: {
    value: "case_all"
  },
  case_read: {
    value: "case_read"
  },
  case_edit: {
    value: "case_edit"
  },
  case_export: {
    value: "case_export"
  },
  case_grouprpt_read: {
    value: "case_grouprpt_read"
  },
  case_rpt_edit: {
    value: "case_rpt_edit"
  },
  //#endregion
  person_all: {
    value: "person_all"
  },
  node_all: {
    value: "node_all"
  },
  group_sca_all: {
    value: "group_sca_all"
  },
  open_sca_all: {
    value: "open_sca_all"
  },
  indv_sca_all: {
    value: "indv_sca_all"
  },
  lb_doc_read: {
    value: "lb_doc_read"
  },
  lb_all: {
    value: "lb_all"
  },
  admin_user_all: {
    value: "admin_user_all"
  }
};
